// material-ui
import { Avatar, Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// assets
import { IconMenu2 } from "@tabler/icons";
// project imports
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import Ncdc from "../../../assets/images/mpower/NCDC.png"
import WhoLogo from "../../../assets/images/mpower/WHO.png"
import MpowerLogo from "../../../assets/images/mpower/mpower2.png"
import MpowerLogo1 from "../../../assets/images/mpower/mpower.png"
// import HeaderLogo from "../../../assets/images/mpower/header.jpg"
// import HeaderLogo2 from "../../../assets/images/mpower/header2.jpg"
// import HeaderLogo3 from "../../../assets/images/mpower/header3.jpg"
// import HeaderTransparent from "../../../assets/images/mpower/headerTransparent.png"

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const theme = useTheme();

  return (

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* 1st box: logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
      </Box>

      {/* 2nd box: center content with 3 images */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: "70px", 
          flexGrow: 1, 
          // backgroundColor: "#ffffff", // White background for all logos
          // borderRadius: "5px", // Circular background
          // padding: "0px", // Adds some padding around the logos to ensure they don't touch the edges of the circular background
          // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          // maxWidth: "30%",
        }}
      >
        {/* <img style={{ width: "500px", height: "70px" }} src={HeaderTransparent} alt="Header Logo" /> */}
        {/* <img style={{ width: "445px", height: "70px" }} src={HeaderLogo3} alt="Header Logo" /> */}
        <Box
          sx={{
            // width: "10px", // Adjust the size of the circular background
            // height: "75px",
             // Makes the background circular
             // White circular background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
             // Optional: Adds a slight shadow for better visibility
          }}
        >
          <img style={{ width: "60px", height: "70px" }} src={Ncdc} alt="NCDC Logo" />
        </Box>
        <Box
          sx={{
            width: "100px",
            height: "75px",
            
            
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          <img style={{ width: "120px", height: "60px" }} src={MpowerLogo} alt="Mpower Logo" />
        </Box>
        <Box
          sx={{
            width: "100px",
            height: "75px",
            
            
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px"
            
          }}
        >
          <img style={{ width: "150px", height: "60px" }} src={WhoLogo} alt="WHO Logo" />
        </Box>
        
      </Box>

      {/* Right side content: Profile and Mobile sections */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end", // Aligns the content to the right
        }}
      >
        {/* Profile Section */}
        <ProfileSection />

        {/* Mobile Header */}
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <MobileSection />
        </Box>
      </Box>
    </Box>

  );
};

export default Header;
